.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 177px;
  margin-bottom: 177px;
  padding: 1rem;

}
.faq_container {
  width: 1208px;
  height: 100%;
  display: flex;
  flex-direction: row;
  top: 177px;
  left: 119px;
  gap: 67px;
  /* grid-template-columns: 1fr 1fr; */
}

.faq_grid_item h2 {
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 57px;
  line-height: 62.4px;
  text-align: left;
}

@media screen and (max-width: 768px) {

  .container {
    padding: 6rem 2rem;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .faq_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 2rem;
  }

  .faq_grid_item img{
    width: 20rem;}

  .faq_grid_item h2 {
    font-size: 2.5rem;
    padding-left: 0;
    text-align: center;
  }
}


