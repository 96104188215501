.hero {
  width: 100%;
  height: 90vh;
  min-height: 803px;
  display: flex;
  background: linear-gradient(#5614d0, #4910b1);
  position: relative;
}

.mask {
  position: absolute;
  right: 0;
  top: -3.5rem;
  z-index: 0;
}

.heroWrapper {
  width: 100%;
  padding: 3rem 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.heroText {
  display: flex;
  flex-direction: row;
}

.heroLeftContent {
  width: 60%;
  text-align: start;
}

.heroRightContent {
  width: 40%;
}

.heroButtons {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}

.heroButton {
  border-radius: 5rem;
  border: 0;
  padding: 0.25rem 4rem;
  height: 4.5rem;
  opacity: 1;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroBtnDownImg {
  width: 3rem;
  padding-left: 1rem;
}

.heroButton:nth-of-type(2) {
  background-color: #3e16a0;
  color: #fff;
  padding: 1.25rem 1.55rem;
}

.heroBtnImg {
  width: 100%;
  padding-right: 0.5rem;
}

.heroModels {
  position: relative;
  right: 6rem;
  top: 2rem;
  width: 36rem;
  /* height: 100vh; */
}

.heroImg {
  position: absolute;
  bottom: -7.1rem;
  left: 15rem;
}

.firstLine {
  font-size: 2.5rem;
  color: #bba1ec;
  display: contents;
  font-weight: 700;
  opacity: 0.7;
}

.secondLine {
  font-size: 3.5rem;
  color: #fff;
  display: contents;
  font-weight: 700;
}

@media screen and (max-width: 768px) {

  .hero {
    height: 130vh;
  }


  .heroText{
  display: flex;
flex-direction: column;}
  .heroWrapper {
    padding: 3rem 2rem;
    flex-direction: column;
  }

  .heroLeftContent {
    width: 100%;
    text-align: center;
  }

  .heroRightContent {
   width: 100%;

  }

  .heroModels {
    width: 20rem;
    height: 60vh;
    right: 0;
   
  }

  .heroImg {
   display: none;
  }

  .heroButtons {
    flex-direction: column;
    align-items: center;
  }

  .heroButton {
    width: 100%;
    font-size: 1.46rem;
    font-weight: 800;
  }

  .heroButton:nth-of-type(2) {
    margin-top: 1rem;
   width: 40%;

  }

  .heroBtnImg{
  width: 6rem;}

  .firstLine {
    font-size: 2rem;
  }

  .secondLine {
    font-size: 3rem;
  }

  .mask{
  top: 20rem;}

}


@media screen and (max-width: 560px) {
  .hero {
    height: 150vh;
  }
  .onDesktop{
    display: none;
  }
 
  }
@media screen and (min-width: 560px) {
  .onMobile{
    display: none;
  }
  .LineBox{
    padding: 0 5em;
  }
  }
