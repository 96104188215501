.container {
  background: #f5efff;
  height: 416px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}

.container h1 {
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: center;
  color: #07193a;
  margin: 0;
}

.container p {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.77px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #07193a;
  margin: 0;
  margin-top: 34px;
  max-width: 1202px;
}

.container2 {
  height: 100%;
  padding-bottom: 5rem;
}

.container2 div {
  margin: 85px 119px;
}

.container2 h3 {
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(7, 25, 58, 1);
  margin-bottom: 23px;
}

.container2 p {
  color: rgba(83, 88, 126, 1);
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.24px;
  letter-spacing: -0.01em;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
    padding: 10rem 0;

  }

  .container h1 {
 
    padding: 2rem;
    font-size: 2.5rem;
  }

  .container p {
    padding: 2rem;
    font-size: 1rem;
    margin-top: 0;
  }

  .container2 {
    height: 100%;
  }

  .container2 div {
    margin: 85px 20px;
  }

  .container2 h3 {
    font-size: 1.5rem;
  }

  .container2 p {
    font-size: 1rem;
  }
}
li{
  text-align: left;
}