/* modal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Added z-index */
  }

  .modalHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: 1.5rem;
    color: #fff;
    background-color: #5614D0;
    position: sticky;
    top: 0;
}
  
  .modalContent {
    background-color: #ffffff;
    width: 100%; /* Changed width to cover the whole screen */
    height: 100%; /* Added height to cover the whole screen */
    overflow: auto; /* Added overflow to enable scrolling if needed */
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    left: 4rem;
    background: none;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
    color: #fff;
  }
  
  .modalBody {
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .modalItem {
    background-color: #fff;
    border-radius: 2rem;
  }

  .modalItem img {
    width: 30rem;
    border-radius: 3rem;
    box-shadow:  5px 5px 10px #c7c7c7,
    -5px -5px 10px #f9f9f9;
  } 
  
  @media screen and (max-width: 768px) {

    .modalHeader{
      font-size: 1.2rem;
    }
    
    .closeButton {
      left: 2rem;
      font-size: 2rem;
    }

    .modalItem img {
      width: 20rem;
      border-radius: 2rem;
    }
  }