.testimonials{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    background: url("../../../assets/Mask_2.png") no-repeat center center/cover #5013C1;
    padding: 4rem 0rem 15rem 0rem;
}

.testHeader{
    font-size: 3rem;
    color: #fff;
    font-weight: 700;
    text-align: start;
    margin-bottom: 2rem;
    padding-left: 6rem;
}

@media screen and (max-width: 768px){
    .testimonials{
        /* padding: 2rem 0rem 6rem 0rem; */
    }
    .testHeader{
        font-size: 2.5rem;
        padding-left: 2rem;
        text-align: center;
    }
}