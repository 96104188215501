.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  width: 100%;
  padding-left: 5rem;
}

.slider {
  width: min(100vw, 800px);
  height: 40vh;
  list-style-type: none;
  display: flex;
  transition: transform 1.25s;
  gap: 2rem;
}

.item {
  position: relative;
  min-width: 100%;
  height: inherit;
  display: flex;
  align-items: center;

}

.testimonial {
width: 100%;
background: #400F9A;
border-radius: 4rem 4rem 0 4rem;
}


.upperDiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 2rem; ;
}

.upperDiv p{
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  text-align: end;
}

.upperDiv span{
  opacity: 0.5;
}

.image {
width: 5.5rem;
height: 5.5rem;
border-radius: 50%;
}

.lowerDiv{
  padding: 1rem 2.25rem;
}

.lowerDiv p{
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: start;
}

.lowerDiv p:nth-of-type(2){
  opacity: 0.5;
}

@keyframes image {
  100% {
    transform: translateX(0);
  }
}

@keyframes testimonial {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes content {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn {
  width: 1.25rem;
  height: 0.75rem;
  border-radius: 1rem;
  border: none;
  background-color: #d9d9d92c;
  
  cursor: pointer;
  margin: 0 0.4rem;
  transition: width 0.3s;
}

.expand {
  width: 3.75rem;
  background: #fff;
}

 @media screen and (max-width: 768px) {
  .main {
    padding-left: 0;
  }
  .slider {
    height: 25vh;
    width: 88%;
    padding: 1rem;
  }
  .item {
    height: 100%;
  }
  .testimonial {
    border-radius: 2.5rem 2.5rem 0 2.5rem;
    height: 12rem;
  }
  .upperDiv {
    padding: 1rem 1rem 0 1rem;
  }
  .upperDiv p {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }
  
  .image {
    width: 3.8rem;
    height: 3.8rem;
  }
  .lowerDiv {
    padding: 0.5rem 1rem;
  }
  .lowerDiv p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  .lowerDiv p:nth-of-type(2) {
    font-size: 0.75rem;
    margin: 0.5rem 0;
  }
  .btn {
    margin: 0 0.4rem;
    height: 0.5rem;
  }
  .expand {
    width: 3.25rem;
  }
} 