.container {
  height: 100%;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(236, 227, 252, 1);
  padding: 6rem 8rem;
  background-image: url("../../../assets/homepage-div-2-bg\ 1.png");
}

.right__div {
  height: 100%;
  padding-top: 220px;
}

.right__div nav {
  margin-bottom: 20px;
}

.container h2 {
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: left;
  color: rgba(77, 18, 185, 1);
  margin-bottom: 68px;
}

.option {
  width: 630px;
  height: 121px;
  padding: 28px 40px 28px 30px;
  gap: 18px;
  border-radius: 61px;
  transition: all ease-in 0.3s;
  cursor: pointer;
  margin: 16px 0;
  background: rgba(0, 0, 0, 0.09);
}

.number {
  width: 66px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 40px;
  background: rgba(37, 49, 76, 1);
}

.number p {
  margin-bottom: 0;
  gap: 0px;
  /* opacity: 0px; */
  font-family: Be Vietnam Pro;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.42px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
}

.option h5 {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.77px;
  letter-spacing: -0.03em;
  text-align: left;
  width: 418px;
  height: 23px;
  gap: 0px;
  /* opacity: 0px; */
  margin-left: 18px;
  color: rgba(97, 86, 143, 1);
}

.option_content {
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  transition: all ease-in 0.3s;
}
.option_content img {
  width: 37.2px;
}
.option_content__left {
  display: flex;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
}

.card {
  transition: all ease-in 0.3s;
  width: 450px;
  min-height: 582.37px;
  padding: 47px 42px 47px 42px;
  gap: 10px;
  border-radius: 50.98px;
  background-color: white;
}

.active {
  box-shadow: 0px 4px 54px 0px #0000002e;
  background-color: white;
  transition: all ease-in 0.3s;
}

.active h5 {
  color: #7037d9;
}

.active .number {
  background: #5614d0;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  width: 100%;
  padding-left: 5rem;
}

.slider {
  width: min(100vw, 800px);
  height: 40vh;
  list-style-type: none;
  display: flex;
  transition: transform 1.25s;
  gap: 2rem;
}

.item {
  position: relative;
  min-width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
}

.testimonial {
  width: 100%;
  background: #400f9a;
  border-radius: 4rem 4rem 0 4rem;
}

.upperDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
}

.upperDiv p {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  text-align: end;
}

.upperDiv span {
  opacity: 0.5;
}

.image {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
}

.lowerDiv {
  padding: 1rem 2.25rem;
}

.lowerDiv p {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: start;
}

.lowerDiv p:nth-of-type(2) {
  opacity: 0.5;
}

@keyframes image {
  100% {
    transform: translateX(0);
  }
}

@keyframes testimonial {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes content {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn {
  width: 1.25rem;
  height: 0.75rem;
  border-radius: 1rem;
  border: none;
  background: rgba(24, 24, 24, 0.18);

  cursor: pointer;
  margin: 0 0.4rem;
  transition: width 0.3s;
}

.expand {
  width: 3.75rem;
  background: rgba(77, 18, 184, 1);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 6rem 2rem;
    flex-direction: column;
    gap: 2rem;
  }
.horizontal_box{
  display: flex;
  width: 100vw;
  overflow: scroll;
}
.option_content{
  width: 500px;
}
.option {

  background: rgba(0, 0, 0, 0.25);
}


  .container h2 {
    font-size: 2.5rem;
    padding-left: 0;
    text-align: center;
  }

  .right__div {
    margin: 0 2rem 2rem 2rem;
    padding: 0;  
    width: 100%;
  }

  .card img{
    width: 100%;
  }

  .option {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    height: 4rem;
    align-items: center;
  }

  .option h5 {
    width: 100%;
    line-height: 1rem;
    font-weight: 600; 
    font-size: 12px;
    margin-left: 0.5rem;
    margin-bottom: 0;
    height: 100%;
  }

  .number {
    width: 40px;
    height: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number p {
    font-size: 1rem;
    margin: 0;
  }

  .card {
    width: 100%;
    min-height: 0;
  }

  .main {
    padding-left: 0;
  }

  .slider {
    height: 30vh;
  }

  .item {
    min-width: 100%;
  }
}
