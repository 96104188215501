.container {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

/* Group 1047 */

.left__div {
  width: 414px;
  margin-left: 120px;
}

.left__div h2 {
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  margin-top: 77px;
  margin-bottom: 29px;
  line-height: 62.4px;
  text-align: left;
}

.left__div p {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.77px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(83, 88, 126, 1);
}

.left__div button {
  width: 394px;
  height: 72px;
  padding: 20px 60px 20px 60px;
  background: #5715d1;
  gap: 21px;
  border-radius: 50px;
  opacity: 1;
  font-family: Be Vietnam Pro;
  font-size: 27.48px;
  font-weight: 700;
  line-height: 35.72px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: none;
  margin-top: 51px;
}

.social {
  width: 394px;
  height: 123px;
  gap: 18px;
  /* opacity: 0px; */
}

.left__div caption {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.77px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 10px;
  width: 394px;
  gap: 0px;
  /* opacity: 0px; */
  color: #5715d1;
}

.right__div {
  /* width: 875px; */
  /* height: 749px; */
  left: 565px;
  gap: 0px;
  /* opacity: 0px; */
}

.container__2 {
  width: 100%;
  height: 933px;
  background-color: #5013c1;
  background-image: url("../../assets//homepage-div-4-bg\ 1.png");
  position: relative;
  display: flex;
  z-index: 0;

  justify-content: center;
}

.container__2 h2 {
  position: relative;
  top: 104px;
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: center;
  color: #f9f9fb;
}

.story__container {
  position: absolute;
  top: 229px;
  z-index: 2000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.story__div__even {
  width: 378px;
  height: 388px;
  padding: 42px 48px 47px 42px;
  border-radius: 60px 60px 0px 60px;
  /* opacity: 0px; */
  z-index: 19999;
  background-color: #ffffff3f;
}
.story__div__even {
  width: 378px;
  height: 388px;
  padding: 42px 48px 47px 42px;
  border-radius: 60px 60px 0px 60px;
  /* opacity: 0px; */
  z-index: 19999;
  background-color: #ffffff3f;
}
.story__div__odd {
  width: 378px;
  height: 388px;
  padding: 42px 48px 47px 42px;
  border-radius: 60px 60px 0px 60px;
  /* opacity: 0px; */
  z-index: 19999;
  background-color: #ffffff;
}

.inner__div__even {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  gap: 2.53px;

  width: 89.19px;
  height: 81px;

  background: #ffffff;
  border-radius: 25px 25px 0px 25px;

  /* Inside auto layout */
  /* flex: none;
order: 0;
flex-grow: 0; */
}

.inner__div__odd {
  /* Frame 26 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  gap: 2.53px;

  width: 89.19px;
  height: 81px;

  background: rgba(87, 21, 209, 1);

  border-radius: 25px 25px 0px 25px;

  /* Inside auto layout */
  /* flex: none;
order: 0;
flex-grow: 0; */
}

.story__div__even h4 {
  width: 288px;
  /* height: 86px; */
  margin: 28px 0;
  gap: 0px;
  top: 151px;
  /* opacity: 0px; */
  font-family: Be Vietnam Pro;
  font-size: 34px;
  font-weight: 600;
  line-height: 43.01px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
}
.story__div__even p {
  width: 295px;
  /* height: 69px; */
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.77px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
}

.story__div__odd h4 {
  width: 288px;
  /* height: 86px; */
  /* position: absolute; */
  margin: 28px 0;
  gap: 0px;
  top: 151px;
  /* opacity: 0px; */
  font-family: Be Vietnam Pro;
  font-size: 34px;
  font-weight: 600;
  line-height: 43.01px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(87, 21, 209, 1);
}
.story__div__odd p {
  /* position: absolute; */
  width: 295px;
  /* height: 69px; */
  gap: 0px;
  /* opacity: 0px; */
  top: 265px;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.77px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(87, 21, 209, 1);
}

.social__icon {
  width: 82px;
  height: 82px;
  background: rgba(236, 227, 252, 1);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social__icon img {
  height: 43px;
}

.social__icons__div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.so_far {
  display: none !important;
}
@media only screen and (max-width: 768px) {
  /* Styles for tablets and larger devices */
  .container {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .so_far {
    display: block !important;
  }

  .social__icons__div {
    justify-content: space-evenly;
  }

  .social__icon {
    width: 62px;
    height: 62px;
    background: rgba(236, 227, 252, 1);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social img {
    /* width: 20px; */
    height: 28px;
  }

  .left__div {
    display: flex;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }

  .left__div button {
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }

  .left__div p {
    text-align: center;
  }
  .right__div img {
    height: 300px;
  }
  .container__2 {
    height: 250vh;
  }
  .container__2 h2 {
    top: 84px; /* Reset top position for larger screens */
  }
  .story__container {
    top: 229px; /* Reset top position for larger screens */
  }

  .story__div__even {
    /* width: 90%; */
    /* height: 90%; */
    padding: 42px 48px 47px 42px;
    border-radius: 60px 60px 0px 60px;
    /* opacity: 0px; */
    z-index: 19999;
    background-color: #ffffff3f;
  }

  .story__div__odd {
    /* width: 90%; */
    /* height: 90%; */
    padding: 42px 48px 47px 42px;
    border-radius: 60px 60px 0px 60px;
    /* opacity: 0px; */
    z-index: 19999;
    background-color: #ffffff;
  }
}
