/* Navbar */
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 6rem;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all 0.5s ease ;
}

.aboutPage {
  background: #fff;
}

.scrolled {
  background: #fff;
  z-index: 999;
}

.navLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 4rem;
  gap: 3rem;
  color: #000;
}

.navLogo img {
  width: 9rem;
}

.scrolled .link {
  color: #5715d1;
}

.link {
  color: #fff;
  font-weight: 700;
  opacity: 0.7;
  text-decoration: none;
}

.aboutPage .link{
  color: #5715d1;
}

.link:hover {
  opacity: 1;
}

.navButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.navButton {
  padding: 0.75rem 1.5rem;
  border: 1px solid white;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navButton img {
  width: 1.25rem;
  margin-left: 0.5rem;
}

.navButton:nth-of-type(2) {
  color: #5715d1;
}

.navButton:nth-of-type(2) img {
  color: #5715d1;
}

.navButton:nth-of-type(1) {
  background: transparent;
  color: #fff;
}

.navButton:nth-of-type(1):hover {
  color: #e9e8e8;
}

.scrolled .navButton {
  border: 1px solid #5715d1;
  color: #5715d1;
}

.aboutPage .navButton {
  color: #5715d1;
  border: 1px solid #5715d1;
}

.scrolled .navButton:nth-of-type(2) {
  background: #5715d1;
  color: #fff;
}

.aboutPage .navButton:nth-of-type(2) {
  background: #5715d1;
  color: #fff;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 11;
}

.aboutPage .hamburger div {
  background: #5715d1;
}

.bar {
  width: 25px;
  height: 3px;
  border-radius: 5px;
  margin: 3px;
  background-color: #fff;
}

.scrolled .bar {
  background-color: #5715d1;
}

/* Slider Menu */

.slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.sliderLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sliderLogo img {
  width: 8rem;
}

.sliderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.sliderLink {
  margin: 1rem 0;
  color: #5715d1;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: 700;
  opacity: 0.7;
}

.sliderLink:hover {
  opacity: 1;
}

.sliderButton {
  margin: 1rem 0;
  padding: 0.75rem 1.25rem;
  border: 1px solid #000;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  width: 11rem;
}

.sliderButton:nth-of-type(1) {
  border: 1px solid #5715d1;
  background: transparent;
  color: #5715d1;
}

.sliderButton:nth-of-type(2) {
  background: #5715d1;
  color: #fff;
}

.sliderButton img {
  width: 1.5rem;
  margin-left: 0.5rem;
}

.closeBtnContainer{
  display: flex;
  justify-content: center;
}
.closeBtn {
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  background-color: #6b1cfd3c;
  width: 3rem;
  height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;

}
.cross{
    color: #5715d1;
    position: relative;
    width: 2rem;
    font-size: 2rem;
    opacity: 1;
    top: -0.20rem;
}
/* Slide-in Animation */
.slideIn {
  transform: translateY(0);
}

/* Media Query for Small Screens */
@media screen and (max-width: 560px) {
  .navbar{
    padding: 1rem 2.5rem;
  }
  .bar {
    width: 25px;
    height: 3px;
  }
  .navLogo img {
    width: 7rem;
  }
  .navLinks,
  .navButtons {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}
