.footerContainer {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: space-between;
  align-items: center;
  background: #000;
  flex-wrap: wrap;
  
}
.ftrContent {
  width: 100%;
  padding: 0 8.5rem 5rem 8.5rem;
}

.topContainer {
  display: flex;
  justify-content: center;
}

.ftrTopContent {
  background: url("../../assets/FooterOverlay_1.png"), #8a55eb;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  top: -9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 64px;
  padding: 4rem 6rem;
  width: 75%;
}

.ftrTopContent p {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  padding: 0.5rem 0;
  display: inline-block;
}

.ftrTopContent span {
  color: #ffffffbb;
  width: 75%;
}

.ftrButton {
  background: #fff;
  color: #000;
  padding: 0.75rem 1.5rem;
  border: 0;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ftrButton:hover {
  box-shadow: 0 0 0.25rem 0.05rem #000;
  cursor: pointer;
}

.ftrButton img {
  width: 1.5rem;
  margin-left: 0.5rem;
}

.ftrMidContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.ftrEnd {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c213d;
  padding: 3rem 0.2rem;
  flex-wrap: wrap;
}

.ftrEnd p {
  color: #fff;
  font-size: 1.3rem;
  margin: 0;
}

.ftrEnd span {
  color: #96909e;
  font-size: 1.3rem;
}
.leftContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.leftContent img {
  width: 11rem;
  margin-bottom: 2rem;
}

.leftContent p {
  color: #bba1ec;
  opacity: 0.5;
  font-weight: 700;
  font-size: 2.25rem;
}

.leftContent span {
  color: #fff;
  font-weight: 700;
  font-size: 3.25rem;
}

.rightContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.rightContent img {
  width: 20rem;
}

.ftrLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ftrLinks .leftContent {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.leftContent .linkContainers {
  align-items: flex-start;
}

.leftContent .linkContainers p {
  color: #999999;
  font-size: 1rem;
  font-weight: 400;
  opacity: 1;
}
.rightContent .linkContainers {
  align-items: flex-end;
  text-align: end;
}
.linkContainers {
  display: flex;
  flex-direction: column;
  width: 18rem;
}

.linkHeadings {
  color: #5013c1;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.links {
  color: #999999;
  /* padding: 0.25rem 0; */
  /* margin-bottom: 1rem; */
  text-decoration: none;
}

@media screen and (max-width: 560px) {
  .ftrContent {
    padding: 1rem 2rem;
  }
  .links {
    font-size: 0.7rem;
  }
  .footerContainer{
    margin-bottom: 70px;
  }
  .leftContent p {
    font-size: 2rem;
  }

  .leftContent span {
    font-size: 2rem;
  }

  .ftrTopContent {
    padding: 1rem 2rem;
    width: 100%;
    align-items: flex-start;
    border-radius: 1.5rem;
    background-position: top;
    top: -7rem;
  }

  .ftrTopContent p {
    font-size: 1rem;
    text-align: start;
  }

  .ftrTopContent span {
    width: 100%;
    font-size: 0.75rem;
    text-align: start;
  }

  .ftrButton {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
  }

  .ftrButton img {
    width: 1rem;
  }

  .ftrMidContent {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .leftContent {
    align-items: center;
  }

  .leftContent img {
    width: 8rem;
  }

  .rightContent img {
    width: 15rem;
  }

  .ftrLinks {
    justify-content: center;
    align-items: center;
  }

  .ftrLinks .leftContent {
    width: 100%;
    justify-content: space-between;
  }

  .leftContent .linkContainers {
    /* margin-right: 2rem; */
  }

  .ftrLinks .rightContent {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .rightContent .linkContainers {
    align-items: flex-start;
    text-align: start;
  }
  .linkContainers {
    width: auto;
    margin-bottom: 3rem;
  }

  .linkHeadings {
    font-size: 1.2rem;
  }

  .leftContent .linkContainers p {
    font-size: 0.75rem;
  }

  .ftrEnd p {
    font-size: 1rem;
    margin: 0;
  }

  .ftrEnd span {
    font-size: 1rem;
  }
}
.stickyButton {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  background-color: white;
  color: #fff;
  border: none;
  z-index: 99;
  border-radius: 0;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.stickyDownload {
  border-radius: 5rem;
  border: 0;
  padding: 0.25rem 3rem;
  /* height: 3.5rem; */
  opacity: 1;
  z-index: 10;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #761CFF;
;
  color :white;

}
.discount{
  font-weight: 700;
  animation: zoom-in-zoom-out 4s ease infinite;
  margin-bottom: 5px;
  color: #8a55eb;

}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}


.heroBtnDownImg {
  width: 3rem;
  padding-left: 1rem;
}

/* Optional styling for the button */
.stickyButton:hover {
  background-color: white;
}
@media (min-width: 768px) {
  .stickyButton {
    display: none; /* Hide the button on larger screens */
  }

}
@media (max-width: 768px) {
  .ftrTopContent {
    background: url("../../assets/FooterOverlay_2.png"), #8a55eb;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;

  }
}
@media screen and (min-width: 560px) {
  .onMobile{
    display: none;
  }
  .LineBox{
    padding: 0 5em;
  }
  }
