.container {
  background-image: url("../../../assets/homepage-div-3-bg\ 1.png");
  width: 100%;
  height: 100%;
  background-size: cover;
}

.container h2 {
  font-family: Be Vietnam Pro;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
  text-align: left;
  margin-bottom: 68px;
  color: rgba(77, 18, 185, 1);
}
.content {
  padding: 137px 120px;
  height: 100%;
}

.card_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 100%;
  gap: 3rem;
}

.card {
  width: 23rem;
  height: 21rem;
  padding: 42px 48px 47px 42px;
  border-radius: 60px 60px 0px 60px;
  /* opacity: 0px; */
  background: rgba(236, 227, 252, 1);
  transition: all ease-in 0.3s;
}

.inner__div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  gap: 2.53px;
  width: 89.19px;
  height: 81px;
  background: rgba(204, 190, 233, 1);
  border-radius: 25px 25px 0px 25px;
}

.card h4 {
  font-family: Be Vietnam Pro;
  font-size: 24px;
  font-weight: 500;
  line-height: 30.36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(83, 88, 126, 1);
  margin: 28px 0;
}

.card p {
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.77px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(83, 88, 126, 0.5);
}

.card:hover {
  background: rgba(77, 18, 185, 1);
}

.card:hover .inner__div {
  background-color: white;
}
.card:hover h4 {
  color: white;
}
.card:hover p {
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .content {
    padding: 137px 20px;
  }
  .card_container {
    gap: 1rem;
    justify-content: center;
  }

  .card {
    padding: 0.9rem;
    width: 9.5rem;
    height: 9.5rem;
    border-radius: 2rem 2rem 0 2rem;

  }
  .inner__div {
    padding: 10px 12px;
    gap: 1.53px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.7rem 0.7rem 0 0.7rem;
  }
  .inner__div img {
    width: 1.2rem;
  }
  .card h4 {
    font-size: 0.8rem;
    margin: 0;
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .card p {
    font-size: 10px;
    line-height: 0.9rem;
  }

}

@media screen and (max-width: 525px) {
  .container h2{
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 2.5rem;
  }
  .content {
    padding: 6rem 0.5rem;
  }
  .card {
    padding: 0.9rem;
    width: 9.5rem;
    width: 9.5rem;
  }
  .inner__div {
    padding: 10px 12px;
    gap: 1.53px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.7rem 0.7rem 0 0.7rem;
  }
  .inner__div img {
    width: 1.2rem;
  }
  .card h4 {
    font-size: 0.8rem;
    margin: 0;
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .card p {
    font-size: 10px;
    line-height: 0.9rem;
  }
}
